import { createStore } from 'vuex'

export default createStore({
  state: {
    language: 'CN'
  },
  getters: {
  },
  mutations: {
    updateLanguage(state,language){
      state.language = language;
    }, 
  },
  actions: {
  },
  modules: {
  }
})
