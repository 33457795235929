import axios from 'axios'
import Qs from 'qs'
import store from '../store'
import auth from './auth'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url 服务地址
  // withCredentials: true, // 跨域请求时发送cookie
  timeout: 10000 // 请求超时时间
})

/**
 * 请求拦截器
 */
 service.interceptors.request.use(
  config => {
    config.headers['version'] = '1.0.0' // 版本号
    config.headers['source'] = 'webSite'
    if (config.url.startsWith('http')) {
      config.baseURL = ''
    } else {
      config.baseURL = process.env.VUE_APP_BASE_API
    }
    // const token = auth.getToken()
    // if (token) {
    //   if (!config.headers['xcyToken']) { // 请求没有指定令牌
    //     config.headers['xcyToken'] = token // 增加令牌
    //   }
    // }
    /** GET\DELETE 请求参数格式化 */
    if (config.method.toUpperCase().trim() === 'GET' || config.method.toUpperCase().trim() === 'DELETE') {
      config.paramsSerializer = (params) => {
        return Qs.stringify(params, { arrayFormat: 'indices', allowDots: true })
      }
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

/**
 * 响应拦截器
 */
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    return res
  },
  error => {
    console.log('response err:' + error) // for debug
    if (error === undefined || error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') {
      // toastr.error('服务请求超时')
      return Promise.reject(error)
    }
    if (error.response) {
      switch (error.response.status) {
        case 400:
          // toastr.error(error.response.data)
          break
        case 401: // 无权限
          //loginDialog({show: true})
          // store.dispatch('user/resetToken').then(() => {
          //   location.reload()
          // })
          // store.dispatch('user/logout')
          break
        case 402: // token过期或无效
          /**
           * 获取刷新token, 获取新的令牌
           * 如果获取新令牌失败则返回登陆页
           */
          // if (getRefreshToken()) {
          //   return service({
          //     url: userApi.apiUrls.getToken,
          //     method: 'POST',
          //     data: { source: getRefreshToken() }
          //   }).then(res => {
          //     if (res.status !== 200) {
          //       removeRefreshToken()
          //       if (isGdTalk()) {
          //         router.push({ path: '/mobileLogin' })
          //       } else {
          //         router.push({ path: '/login' })
          //       }
          //     }
          //     setToken(res.data.info)
          //     const config = error.config
          //     config.headers.token = res.data.info
          //     return service(config)
          //   }).catch(reason => {
          //     if (isGdTalk()) {
          //       router.push({ path: '/mobileLogin' })
          //     } else {
          //       router.push({ path: '/login' })
          //     }
          //   })
          // } else {
          //   if (isGdTalk()) {
          //     router.push({ path: '/mobileLogin' })
          //   } else {
          //     router.push({ path: '/login' })
          //   }
          // }
          break
        case 424:
          // Notification({
          //   title: '错误',
          //   message: error.response.data.errorMessage,
          //   type: 'error'
          // })
          return
      }
    }
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service