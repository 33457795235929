<!-- 首页 -->
<template>
  <div class="my-index-bg">
    <full-page :options="options" ref="fullpage">
      <div class="section my-bg-auto" >
        <div class="container pb-5 px-3">
          <div class="pb-5 pb-md-0 mb-5" style="position: relative; z-index: 2; background-color: transparent;">
            <div class="my-index-shadow-text mb-2" >{{ language == 'CN' ? '浙江新澄宇环境科技有限公司' : 'Zhejiang Neo Pristine Cosmos Environmental Technology Co., Ltd' }}</div>
            <div class="my-index-shadow-text mb-5"  style="font-size: 12px;">{{ language == 'CN' ? 'Zhejiang Neo Pristine Cosmos Environmental Technology Co., Ltd' : '' }}</div>
            
            <h1 class="my-index-shadow-text mb-2" :style="!isMobileDevice ? 'letter-spacing: 5px;' : ''" >{{ language == 'CN' ? indexClassData1.name : indexClassData1.nameEn }}</h1>
            <h1 class="my-index-shadow-text mb-4" :style="!isMobileDevice ? 'letter-spacing: 5px;' : ''" v-if="language == 'CN'">{{ indexClassData1.remark }}</h1>
            <div class="my-index-shadow-text" v-html="language == 'CN' ? indexClassData1.detail : indexClassData1.detailEn"></div>
          </div>
          <div class="my-video-back object-fit-cover" v-if="isMobileDevice">
            <img src="../assets/video/index-video_iSpt.png" class="img-fluid" alt="">
          </div>
          <video autoplay="autoplay" muted loop playsinline class="my-video-back object-fit-cover" v-else>
            <source src="../assets/video/index-video.mp4" type="video/mp4" />
            <source data-src="../assets/video/index-video.webm" type="video/webm" />
          </video>
        </div>
      </div>
      <!-- 关于我们 -->
      <div class="section index-section-2 my-bg-auto" id="index_about" style="overflow-x: hidden !important;">
        <div class="container" style="overflow-x: hidden !important;">
          <div class="row pb-5">
            <div class="col-lg-3">
              <h2 class="mb-3 mb-md-4 my-title-color" :class="isMobileDevice ? 'text-center' : ''">{{ language == 'CN' ? indexClassData2.name ? indexClassData2.name.substring(3) : '' : indexClassData2.nameEn }}</h2>
              <div class="mb-2 mb-lg-4" style="width: 50px; height: 1px; background: #333; " v-show="!isMobileDevice"></div>
              <div v-html="language == 'CN' ? indexClassData2.detail : indexClassData2.detailEn"></div>
              <a :href="indexClassData2.remark" class="my-index-about-btn mt-2 mt-lg-5" v-show="!isMobileDevice">{{ language == 'CN' ? '查看更多'  : 'MORE' }}<i class="bi bi-chevron-right"></i></a>
            </div>
            <div class="col-lg-9 scrollbar" :style="isMobileDevice? 'width:100%; overflow-y: hidden; ':''">
              <div :style="isMobileDevice? 'width:550px;':''">
                <div class="d-flex flex-equal" :class="isMobileDevice ? 'text-center' : ''">
                  <div class="px-2 py-2 px-lg-3 py-lg-0" v-for="(item,index) in indexDetailListData2" :key="index">
                    <img :src="item.imageUrl" class="img-fluid mb-3" alt="" />
                    <h2 class="display-5 my-title-color">{{ language == 'CN' ? item.subhead : item.subheadEn }} <text class="display-6">+</text></h2>
                    <div class="mb-2 mb-lg-4" style="width: 20px; height: 2px; background: #333;" :class="isMobileDevice ? 'm-auto' : ''"></div>
                    <p>{{ language == 'CN' ? item.title : item.titleEn }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-center" v-show="isMobileDevice">
              <a :href="indexClassData2.remark" class="my-title-color mt-2">{{ language == 'CN' ? '查看更多'  : 'MORE' }}<i class="bi bi-chevron-right"></i></a>
            </div>
          </div>
        </div>
      </div>
      <!-- 主营业务 -->
      <div class="section index-section-3 my-bg-auto" id="index_business">
        <div class="container pb-5">
          <div class="pb-2 py-md-5 text-center">
            <h2 class="mb-md-4 my-title-color">{{ language == 'CN' ? indexClassData3.name ? indexClassData3.name.substring(3) : '' : indexClassData3.nameEn }}</h2>
            <div class="mb-4" style="width: 50px; height: 1px; background: #333;  margin: 0 auto;"></div>
          </div>
          <div class="row px-4 px-lg-0">
            <div class="col-lg-4 px-4 mb-2 mb-lg-0" v-for="(item,index) in indexDetailListData3" :key="index">
              <div class="card my-card border-0" :style="isMobileDevice ? 'height: 130px; overflow: hidden;':''">
                <img :src="item.imageUrl" class="img-fluid" style="border-radius: 0px ;" alt="">
                <div class="card-body p-lg-4" :class="isMobileDevice ? 'my-phone-zyye' : ''">
                  <h6 class="text-center my-title-color" :class="isMobileDevice ? 'my-phone-zyye-text' : ''">{{ language == 'CN' ? item.title : item.titleEn }}</h6>
                </div>
              </div>
            </div>
          </div>
          <a :href="indexClassData3.remark" class="my-index-about-btn m-auto mt-5 mb-1" v-show="!isMobileDevice">{{ language == 'CN' ? '查看更多'  : 'MORE' }}<i class="bi bi-chevron-right"></i></a>
        </div>
      </div>
      <!-- 核心技术 -->
      <div class="section index-section-4 my-bg-auto" id="index_core">
        <div class="container">
          <div class="py-md-2 text-center">
            <h2 class="mb-md-4 text-white">{{ language == 'CN' ? indexClassData4.name ? indexClassData4.name.substring(3) : '' : indexClassData4.nameEn }}</h2>
            <div class="mb-4" style="width: 50px; height: 1px; background: #FFF;  margin: 0 auto;"></div>
          </div>
          <div class="row g-3 text-center">
            <div class="col-lg-3" v-for="(item,index) in indexDetailListData4" :key="index">
              <div :class="isMobileDevice ? 'my-index-c-phone-box p-3' : 'my-index-c-box py-3 px-2'" >
                <img :src="item.imageUrl" class="img" :class="!isMobileDevice ? 'm-auto' : ''" alt="">
                <div class="text-white" :class="!isMobileDevice ? 'two-lines mt-3' : 'my-index-c-phone-text'">{{ language == 'CN' ? item.title : item.titleEn }}</div>
              </div>
            </div>
          </div>
          <a :href="indexClassData4.remark" class="my-index-about-btn m-auto text-white mt-5" style="border-color:#FFF" v-if="!isMobileDevice">{{ language == 'CN' ? '查看更多'  : 'MORE' }}<i class="bi bi-chevron-right"></i></a>
          <div class="text-center pb-5 mt-4" v-else>
            <a :href="indexClassData4.remark" class="text-white">{{ language == 'CN' ? '查看更多'  : 'MORE' }}<i class="bi bi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!-- 工程案例 -->
      <div class="section index-section-5 my-bg-auto" id="index_project">
        <div class="container">
          <div class="row g-2 g-lg-4">
            <div class="col-12 col-lg-3">
              <div :class="isMobileDevice ? 'text-center' : ''" :style="!isMobileDevice?'width: 90%;':''">
                <h2 class="mb-2 mb-md-4 my-title-color">{{ language == 'CN' ? indexClassData5.name ? indexClassData5.name.substring(3) : '' : indexClassData5.nameEn }}</h2>
                <div class="mb-2 mb-md-4" style="width: 50px; height: 1px; background: #333;" v-show="!isMobileDevice"></div>
                <div v-html="language == 'CN' ? indexClassData5.detail : indexClassData5.detailEn"></div>
                <a :href="indexClassData5.remark" class="my-index-about-btn mt-2 mt-lg-5" v-show="!isMobileDevice">{{ language == 'CN' ? '查看更多'  : 'MORE' }}<i class="bi bi-chevron-right"></i></a>
              </div>
            </div> 
            <template v-if="!isMobileDevice">
              <div class="col-lg-3" v-for="(item,index) in indexDetailListData5" :key="index">
                <img :src="item.imageUrl" class="img-fluid"  style="height: 180px;" alt="" v-if="item.imageUrl" />
                <div class="my-3" style="width: 25px; height: 1px; background: #333;"></div>
                <h6 class="mb-2 my-title-color text-truncate">{{ language == 'CN' ? item.title : item.titleEn }}</h6>
                <p class="text-truncate">{{ language == 'CN' ? item.subhead : item.subheadEn }}</p>
              </div>
            </template>
            <div v-else>
              <van-swipe class="my-swipe" :autoplay="5000" indicator-color="#005BAC">
                <van-swipe-item v-for="(item,index) in indexDetailListData5" :key="index">
                  <div class="card border-0" >
                    <img :src="item.imageUrl" class="card-img-top" style="height: 180px;" v-if="item.imageUrl" alt="">
                    <div class="card-body"> 
                      <h6 class="my-title-color text-truncate" style="font-weight: normal">{{ language == 'CN' ? item.title : item.titleEn }}</h6>
                      <p class="card-text text-truncate mb-2" >{{ language == 'CN' ? item.subhead : item.subheadEn }}</p>
                    </div>
                  </div>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>
          <div class="text-center pb-5 mt-4" v-show="isMobileDevice">
            <a :href="indexClassData5.remark" class="my-title-color">{{ language == 'CN' ? '查看更多'  : 'MORE' }}<i class="bi bi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!-- 公司动态 -->
      <div class="section index-section-6 my-bg-auto" id="index_company">
        <div class="container">
          <div class="py-3 py-md-5 text-center">
            <h2 class="mb-md-4 my-title-color">{{ language == 'CN' ? indexClassData6.name ? indexClassData6.name.substring(3) : '' : indexClassData6.nameEn }}</h2>
            <div class="mb-4" style="width: 50px; height: 1px; background: #005BAC;  margin: 0 auto;" v-show="!isMobileDevice"></div>
          </div>
          <div class="row g-4" v-if="!isMobileDevice">
            <div class="col-6 col-lg-3" v-for="(item,index) in indexDetailListData6" :key="index">
              <div class="card border-0" style="background-color: transparent">
                <img :src="item.imageUrl" class="card-img-top" alt="" style="height: 180px;" v-if="item.imageUrl">
                <div class="card-body p-2 py-lg-3">
                  <div class="my-title-color mb-2" style="font-size: 0.9rem;">{{ item.writeTime ? item.writeTime.substring(0, 10):'' }}</div>
                  <h6 class="my-title-color text-truncate">{{ language == 'CN' ? item.title : item.titleEn }}</h6>
                  <p class="card-text text-truncate">{{ language == 'CN' ? item.subhead : item.subheadEn }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <van-swipe class="my-swipe" :autoplay="5000" indicator-color="#005BAC">
              <van-swipe-item v-for="(item,index) in indexDetailListData6" :key="index">
                <div class="card border-0">
                  <img :src="item.imageUrl" class="card-img-top" alt="" style="height: 180px;" v-if="item.imageUrl">
                  <div class="card-body">
                    <h6 class="my-title-color text-truncate" style="font-weight: normal">{{ language == 'CN' ? item.title : item.titleEn }}</h6>
                    <p class="card-text text-truncate mb-2">{{ language == 'CN' ? item.subhead : item.subheadEn }}</p>
                    <div class="my-title-color" style="font-size: 0.9rem;">{{ item.writeTime ? item.writeTime.substring(0, 10):'' }}</div>
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
          <a :href="indexClassData6.remark" class="my-index-about-btn m-auto mt-5 mb-2" v-if="!isMobileDevice">{{ language == 'CN' ? '查看更多'  : 'MORE' }}<i class="bi bi-chevron-right"></i></a>
          <div class="text-center pb-5 mt-5" v-else>
            <a :href="indexClassData6.remark" class="my-title-color">{{ language == 'CN' ? '查看更多'  : 'MORE' }}<i class="bi bi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!-- 联系我们 -->
      <div class="section index-section-7 my-bg-auto" id="index_contact">
        <div class="container">
          <div class="row  pb-5 mx-lg-5 px-lg-5 g-lg-4">
            <div class="col-lg-7 text-center order-lg-last">
              <img :src="indexClassData7.icon" class="img-fluid" :style="isMobileDevice?'width: 80%;':''" alt="" />
            </div>
            <div class="col-lg-5">
              <div class="mx-lg-5 py-lg-5" :class="isMobileDevice?'text-center':''">
                <h2 class="mb-4 mb-md-4 my-title-color">{{ language == 'CN' ? indexClassData7.name ? indexClassData7.name.substring(3) : '' : indexClassData7.nameEn }}</h2>
                <div class="mb-4" style="width: 50px; height: 1px; background: #333; " v-show="!isMobileDevice"></div>
                <div v-html="language == 'CN' ? indexClassData7.detail : indexClassData7.detailEn"></div>
                <div class="mt-5">
                  <a :href="indexClassData7.remark" :class="isMobileDevice?'my-title-color':'my-index-about-btn'">{{ language == 'CN' ? '查看更多'  : 'MORE' }}<i class="bi bi-chevron-right"></i></a>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>

      <div class="section fp-auto-height" v-if="!isMobileDevice">
        <footer class="my-appbottom-box text-muted border-top">
          <div class="container-lg" style="display: block">
            <div class="row">
              <div class="col-lg-6 col-ml-12 text-white">
                © 2020-2023 浙江新澄宇环境科技有限公司
              </div>
              <div class="col-lg-6 col-ml-12">
                <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">浙ICP备2023003540号-1</a>
              </div>
            </div>
          </div>
        </footer>
      </div>

    </full-page>

  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs, getCurrentInstance, computed } from 'vue'
import { useStore } from 'vuex'
import { isMobileDevice, getClassInfo, getClassDetailListQuery, getClassDetailList, format } from '../../src/common/common'
import $ from 'jquery'

export default {
  name: 'HomeView',
  components: {
  },
  setup() {
    let { proxy } = getCurrentInstance();  //等于this
    const store = useStore();
    const state = reactive({
      isMobileDevice: false, //是否手机端
      indexDetailData: [],
      indexClassData1: [],
      indexClassData2: [],
      indexDetailListData2: [],
      indexClassData3: [],
      indexDetailListData3: [],
      indexClassData4: [],
      indexDetailListData4: [],
      indexClassData5: [],
      indexDetailListData5: [],
      indexClassData6: [],
      indexDetailListData6: [],
      indexClassData7: [],
      options: {
        //afterLoad: this.afterLoad,
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE", // 这个key 好像是无效的
        //为每个section设置背景色[并不需要]
        sectionsColor: [
          // "#41b883",
          // "#ff5f45",
          // "#0798ec",
        ],
        afterLoad:function(anchorLink, index){
          if(index.index == 0){
            $('#index_about').find('.container').hide();
            $('#index_business').find('.container').hide();
            $('#index_core').find('.container').hide();
            $('#index_project').find('.container').hide();
            $('#index_company').find('.container').hide();
            $('#index_contact').find('.container').hide();

            if(!state.isMobileDevice){
              var video = document.querySelector('video')
              video.play()
            }
          }
          else if(index.index == 1){
            setTimeout(function (){
              $('#index_about').find('.container').show();
              $('#index_about').find('.container').addClass('animate__animated animate__bounceInLeft');
            },100);
          }
          else if(index.index == 2){
            setTimeout(function (){
              $('#index_business').find('.container').show();
              $('#index_business').find('.container').addClass('animate__animated animate__bounceInRight');
            },100);
          }
          else if(index.index == 3){
            setTimeout(function (){
              $('#index_core').find('.container').show();
              $('#index_core').find('.container').addClass('animate__animated animate__bounceInLeft');
            },100);
          }
          else if(index.index == 4){
            setTimeout(function (){
              //[处理不能滚动问题]
              //获取【工程案例】栏目下前3条的内容
              getClassDetailList('1625694534529302528',1,3).then((res) => {
                state.indexDetailListData5 = res;
              })
              $('#index_project').find('.container').show();
              $('#index_project').find('.container').addClass('animate__animated animate__bounceInRight');
            },100);
          }
          else if(index.index == 5){
            setTimeout(function (){
              //[处理不能滚动问题]
              //获取【公司动态】栏目下前4条的内容
              getClassDetailList('1625694888536948736',1,4).then((res) => {
                state.indexDetailListData6 = res;
              })
              $('#index_company').find('.container').show();
              $('#index_company').find('.container').addClass('animate__animated animate__bounceInLeft');
            },100);
          }
          else if(index.index == 6){
            setTimeout(function (){
              $('#index_contact').find('.container').show();
              $('#index_contact').find('.container').addClass('animate__animated animate__bounceInRight');
            },100);
          }
          else if(index.index == 7){
            $('#index_contact').find('.container').show();
          }
          // //处理滚动条问题
          $('.fp-overflow').css('overflow','hidden')
        },
        //[离开的效果先不展示]
        onLeave:function(index, direction){
          // if(index.index == 1){
          //   $('#index_about').find('.container').hide();
          //   $('#index_about').find('.container').removeClass('animate__animated animate__zoomIn')
          // }else if(index.index == 2){
          //   setTimeout(function (){
          //     $('#index_business').find('.container').hide();
          //     $('#index_business').find('.container').removeClass('animate__animated animate__slideInLeft');
          //   },100);
          // }else if(index.index == 3){
          //   setTimeout(function (){
          //     $('#index_core').find('.container').hide();
          //     $('#index_core').find('.container').removeClass('animate__animated animate__slideInDown');
          //   },100);
          // }else if(index.index == 4){
          //   setTimeout(function (){
          //     $('#index_project').find('.container').hide();
          //     $('#index_project').find('.container').removeClass('animate__animated animate__zoomInUp');
          //   },100);
          // }else if(index.index == 5){
          //   setTimeout(function (){
          //     $('#index_company').find('.container').hide();
          //     $('#index_company').find('.container').removeClass('animate__animated animate__zoomInDown');
          //   },100);
          // }else if(index.index == 6){
          //   setTimeout(function (){
          //     //$('#index_contact').find('.container').hide();
          //     $('#index_contact').find('.container').removeClass('animate__animated animate__zoomInUp');
          //   },100);
          // }else if(index.index == 7){
          //   $('#index_contact').find('.container').show();
          // }
        }
      }
    })

    //初始化数据
    onMounted(() => {
      state.isMobileDevice = isMobileDevice();

      //首页
      getClassInfo('1625751299417473024').then((res) => {
        state.indexDetailData = res;
        
        //首页-第一屏
        getClassInfo('1627541442575069184').then((res) => {
          state.indexClassData1 = res;
        })

        //首页-关于我们
        getClassInfo('1625752654269939712').then((res) => {
          state.indexClassData2 = res;
          //获取栏目下的内容
          getClassDetailListQuery('1625752654269939712','desc').then((res) => {
            state.indexDetailListData2 = res;
          })
        })

        //首页-主营业务
        getClassInfo('1627560932931137536').then((res) => {
          state.indexClassData3 = res;
          //获取栏目下的内容
          getClassDetailListQuery('1627560932931137536','desc').then((res) => {
            state.indexDetailListData3 = res;
          })
        })
        
        //首页-核心技术
        getClassInfo('1625753731883106304').then((res) => {
          state.indexClassData4 = res;
          //获取栏目下的内容
          getClassDetailListQuery('1625753731883106304','desc').then((res) => {
            state.indexDetailListData4 = res;
          })
        })

        //首页-工程案例
        getClassInfo('1625753512990769152').then((res) => {
          state.indexClassData5 = res;
        })

        //首页-公司动态
        getClassInfo('1627565284093718528').then((res) => {
          state.indexClassData6 = res;
        })
        
        //首页-联系我们
        getClassInfo('1625754142618714112').then((res) => {
          state.indexClassData7 = res;
        })

      })
    })

    //当前网站语言
    const language = computed(() => {
      return store.state.language;
    })

    return {
      ...toRefs(state),
      language
    }
  },

}
</script>


<style>
  .fp-watermark {
    display: none !important;
  }
  .my-swipe .van-swipe-item {
    background-color: transparent;
  }
  .my-swipe .van-swipe__indicator{
    border: 1px solid #005BAC !important;
  }
  .fp-watermark{
    display: none;
  }
  .scrollbar::-webkit-scrollbar {
    height: 0;
    width: 0;
    color: transparent;
  }
  .two-lines{
    text-overflow:-o-ellipsis-lastline; 
    overflow:hidden; 
    text-overflow: ellipsis; 
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
  }
</style>

<style lang="scss" scoped>
.my-index-bg{
  .my-video-back{
    width: 100vw;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .index-section-1-phone{
    background: url('../assets/video/index-video_iSpt.png') 0 0 no-repeat; 
  }
  .index-section-2{
    background: url('../assets/index-banner-2.jpg') 0 0 no-repeat; 
  }
  .index-section-3{
    background: url('../assets/index-banner-3.jpg') 0 0 no-repeat; 
  }
  .index-section-4{
    background: url('../assets/index-banner-4.jpg') 0 0 no-repeat; 
  }
  .index-section-5{
    background: url('../assets/index-banner-5.jpg') 0 0 no-repeat; 
  }
  .index-section-6{
    background: url('../assets/index-banner-6.jpg') 0 0 no-repeat; 
  }
  .index-section-7{
    background: url('../assets/index-banner-7.jpg') 0 0 no-repeat; 
  }
  .my-index-shadow-text{
    text-shadow: 0px 0px 8px #0D48B4;
    color: #FFFFFF;
  }
  .my-bg-auto{
    background-size: cover; 
    background-position: center;
    background-color: #F8F9FA;
  }
  .my-index-about-btn{
    display: block;
    width: 110px;
    height: 40px;
    line-height: 38px;
    border: 1px solid #005BAC;
    color: #005BAC;
    text-align: center;
    font-size: 14px;
  }
  .my-index-c-box{
    height: 240px;
    background: rgba(0,22,157,0.15);
    border: 1px solid #C4D2FF;
    box-shadow: 0px 2px 8px 0px rgba(15,46,140,0.5);
    border-radius: 10px;
    color: #FFF;
    font-size: 1rem;
    .img{
      width: 170px;
      height: 148px;
    }
  }
  .my-index-c-phone-box{
    background: rgba(0,22,157,0.15);
    border: 1px solid #C4D2FF;
    box-shadow: 0px 2px 8px 0px rgba(15,46,140,0.5);
    border-radius: 5px;
    display: flex;
    .img{
      width: 59.5px;
      height: 51px;
    }
  }
  .my-index-c-phone-text{
    width: 100%;
    height: 51px;
    margin-left: 15px;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-top: 20px;
  }
  .my-phone-zyye{
    width: 100%;
    position: absolute;
    top: 25%;
    .my-phone-zyye-text{
      padding: 0;
      margin: 0;
      display: block;
      width: 200px;
      padding: 10px;
      text-align: center;
      background: rgba(255,255,255,0.8);
      box-shadow: 2px 2px 20px 0px rgba(8,76,153,0.25);
      margin: 0 auto;
    }
  }
}
</style>
