import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'home',
    component: HomeView,
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/core',
    name: 'core',
    component: () => import(/* webpackChunkName: "core" */ '../views/CoreView.vue'),
    meta: {
      title: '核心技术'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
    meta: {
      title: '联系我们'
    }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import(/* webpackChunkName: "company" */ '../views/CompanyView.vue'),
    meta: {
      title: '公司动态'
    }
  },
  {
    path: '/project',
    name: 'project',
    component: () => import(/* webpackChunkName: "project" */ '../views/ProjectView.vue'),
    meta: {
      title: '工程案例'
    }
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/DetailView.vue'),
    meta: {
      title: '详情'
    }
    
  },
  {
    path: '/business',
    name: 'business',
    component: () => import(/* webpackChunkName: "business" */ '../views/BusinessView.vue'),
    meta: {
      title: '主营业务'
    }
  }
  ,
  {
    path: '/business/detail',
    name: 'businessDetail',
    component: () => import(/* webpackChunkName: "businessDetail" */ '../views/BusinessDetailView.vue'),
    meta: {
      title: '业务详情'
    }
  }

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
