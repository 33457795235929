import articleApi from '../api/articleApi'

//当前环境是否手机端
export const isMobileDevice = () => {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent)
};

//获取栏目详情
export const getClassInfo = (classCode) => {
  return articleApi.articleClassList({
    code: classCode,
    isAsc: 'asc',
    orderByColumn: 'write_time'
  }).then((res) => {
    if (res.data) {
      return res.data[0]
    }
  });
};

//获取指定栏目下所有子栏目
export const getParentClass = (classCode) => {
  return articleApi.articleClassList({
    parentCode: classCode
  }).then((res) => {
    if (res.data) {
      return res.data
    }
  });
};

//获取栏目相关文章列表[分页]
export const getClassDetailList = (classCode,pageNum,pageSize) => {
  return articleApi.articleRecordList({
    classCode: classCode,
    pageNum: pageNum,
    pageSize: pageSize,
    isAsc: 'asc',
    orderByColumn: 'write_time'
  }).then((res) => {
    if (res.rows) {
      return res.rows
    }
  });
};

//获取栏目相关文章列表
export const getClassDetailListQuery = (classCode,orderByType) => {
  var isasc = orderByType == null ? 'asc' : orderByType
  return articleApi.articleRecordListQuery({
    classCode: classCode,
    pageNum: 1,
    pageSize: 999,
    isAsc: isasc,
    orderByColumn: 'write_time'
  }).then((res) => {
    if (res.rows) {
      return res.rows
    }
  });
};



