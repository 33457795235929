import request from '@/utils/request'

/**
 * 目录 + 文章相关接口
 */
//const domain = '/article/articleRecord'

/**
 * 基础url
 */
const baseUrl = {
    PAGE: '/article',
}
/**
 * 例外url
 */
const exceptionUrl = {

}

const apiUrl = {
    ...baseUrl,
    ...exceptionUrl
}

export default {
    /**目录列表*/
    articleClassList(params) {
        return request({
            url: apiUrl.PAGE + '/articleClass/list',
            method: 'GET',
            params
        })
    },
    articleClassListQuery(params) {
        return request({
            url: apiUrl.PAGE + '/articleClass/listQuery',
            method: 'GET',
            params
        })
    },
    /**文章列表*/
    articleRecordList(params) {
        return request({
            url: apiUrl.PAGE + '/articleRecord/list',
            method: 'GET',
            params
        })
    },
    /**查询指定分类下的所有文章*/
    articleRecordListQuery(params) {
        return request({
            url: apiUrl.PAGE + '/articleRecord/listQuery',
            method: 'GET',
            params
        })
    },
    /**查询指定文章*/
    detail(params) {
        return request({
            url: apiUrl.PAGE + '/articleRecord/' +params.id,
            method: 'GET',
        })
    },

    /**联系我们*/
    contactUs(data) {
        return request({
            url: '/contactUs',
            method: 'POST',
            data
        })
    }
}