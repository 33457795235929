import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {Swipe,SwipeItem,Toast } from 'vant'
import vfp from 'vue-fullpage.js'
import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'vant/lib/index.css'
import 'animate.css'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(vfp)

app.use(Swipe)
app.use(SwipeItem)
app.use(Toast)

app.mount('#app')