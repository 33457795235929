<template>
  <!-- 导航 -->
  <nav class="navbar navbar-expand-lg bg-body-tertiary" :class="isMobileDevice ? 'sticky-top' : 'fixed-top'" style="background: #FFF;">
    <div class="container-lg">
      <a class="navbar-brand" href="#">
        <img src="@/assets/logo.png"  class="img-fluid" style="max-height: 41px; max-width: 220px;" alt="新澄宇logo"/>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item" v-for="(item,index) in navListData" :key="index">
            <a class="nav-link" :class="currentHref.indexOf(item.remark) != -1 ? 'active' : ''" :href="item.remark" style="position: relative;">
              {{language == 'CN' ? item.name : item.nameEn}}
              <span class="my-nav-line" v-if="!isMobileDevice"></span>
            </a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link active" href="/index">首页</a> 
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/about">关于我们</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/business">主营业务</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/core">核心技术</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/project">工程案例</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/company">公司动态</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/contact">联系我们</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link my-en-btn" href="#" @click="cutLanguage()">{{language == 'CN' ? 'EN' : 'CN'}}</a>
          </li>
        </ul>
        <!-- 预留个搜索 -->
        <!-- <form class="d-flex" role="search">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
      </div>
    </div>
  </nav>

  <!-- 中间内容 -->
  <router-view/>

  <!-- 底部 -->
  <div class="my-appbottom-box text-muted border-top" >
    <div class="container-lg" style=" display: block;">
      <div class="row text-white">
        <div class="col-lg-6">© 2020—2023 浙江新澄宇环境科技有限公司</div>
        <div class="col-lg-6"><a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">浙ICP备2023003540号-1</a></div>
      </div>
    </div>
  </div>
</template>


<script>
import { onMounted, reactive, ref, toRefs, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { isMobileDevice } from '../src/common/common'
import articleApi from './api/articleApi'

export default {
  name: '',
  components: {},
  setup() {
    let { proxy } = getCurrentInstance();  //等于this
    const store = useStore();
    const state = reactive({
      currentHref: window.location.href,
      language: localStorage.getItem("language") || "CN",  //当前语言
      isMobileDevice: false, //是否移动端
      navListData: [], //导航列表
    })

    //初始化数据
    onMounted(() => {
      state.isMobileDevice = isMobileDevice();
      if(state.language == 'CN'){
        store.commit('updateLanguage', 'CN')
      }else{
        store.commit('updateLanguage', 'EN')
      }
      getClassListQuery();
    })

    //切换语言
    const cutLanguage = () => {
      if(state.language == 'CN' ){
        //设为英文
        state.language = 'EN';
        localStorage.setItem("language","EN"); //设缓存
        store.commit('updateLanguage','EN') //设全局
      }else{
        //设为中文
        state.language = 'CN';
        localStorage.setItem("language","CN");
        store.commit('updateLanguage','CN')
      }
    }
    
    //获取一级栏目作为导航
    const getClassListQuery = () => {
      articleApi.articleClassListQuery({
        parentCode: 0
      }).then((res) => {
        if (res.rows) {
          state.navListData = res.rows;
        }
      });
    }

    return {
      ...toRefs(state),
      cutLanguage,
      getClassListQuery,
    }
  },

  mounted() {
    //console.log(process.env)
  },

  
}
</script>

<style>
  body a{
    text-decoration: none !important;
  }
  body p{
    font-size: 0.9rem !important;
    line-height: 1.6rem !important;
    margin: 0 !important;
  }
  .my-title-color{
    color: #005BAC !important;
  }
</style>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

nav {
  padding: 0.5rem 1rem;
  a {
    color: #414141;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
  a.active{
    color: #005BAC !important;
  }
  .nav-link{
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
  .my-en-btn{
    font-weight: bold;
    color: #005BAC !important;
  }
  .active .my-nav-line{
    display: block;
    width: 20px;
    height: 3px;
    background: #005BAC;
    position: absolute;
    bottom: -13px;
    left: calc(50% - 10px);
    border-radius: 2px;
  }
}
.my-appbottom-box{
  background: #005BAC;
  text-align: center;
  padding: 1.5rem 0 !important;
  color: #FFF !important;
  font-size: 14px !important;
  a{
    color: #FFF;
  }
  a:hover{
    color: #FFF;
  }
}
</style>
